import React, { useEffect } from 'react';
import { INTERVIEW_STATUSES, USER_TYPES } from '@er/data-hiring';
import { withRequestContext } from '../../middleware/withRequestContext';
import BaseLayout from '@er/ui-lib/lib/common/shared/base-layout';
import { Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import InterviewsListPage, {
  DISPLAY_INTERVIEWS_MODE,
} from 'apps/hiring/components/candidate-portal/candidate-jobs/interviews-list';
import { InterviewTabs, INTERVIEW_TABS } from '@er/ui-lib';
import { useRouter } from 'next/router';
import { authenticateLoggedInCompanyUser } from '../users/me';
import { getMyInterviews } from '../api/interviews/filters/my-interviews';

const fetchFetcher = (url) => fetch(url).then((r) => r.json());

export const defaultStatusFilter = {
  [INTERVIEW_STATUSES.SCHEDULED]: true,
  [INTERVIEW_STATUSES.CANCELED]: false,
  [INTERVIEW_STATUSES.COMPLETED]: false,
  [INTERVIEW_STATUSES.REQUESTED]: false,
};

export enum POST_LOGIN_REDIRECTS_TYPES {
  MY_AVAILABILITY = 'MyAvailability',
  CANDIDATE_RESUME = 'CandidateResume',
}

export const getServerSideProps = async ({ req, res, query }) => {
  await withRequestContext(req, res);

  const { openMyAvailability } = query;

  const authCheck = await authenticateLoggedInCompanyUser(req);

  if (authCheck.redirect) {
    if (openMyAvailability) {
      return {
        redirect: {
          destination: `/?redirect=${POST_LOGIN_REDIRECTS_TYPES.MY_AVAILABILITY}`,
          permanent: false,
        },
      };
    }

    return { redirect: { destination: '/' } };
  }

  const user = authCheck.props.user;

  const interviews = await getMyInterviews(
    user,
    {
      statusFilter: defaultStatusFilter,
    },
    user.company._id
  );

  let myAvailabilityInitialState = false;

  if (openMyAvailability) {
    myAvailabilityInitialState = true;
  }

  return {
    props: {
      user: JSON.parse(JSON.stringify(user)),
      interviews: JSON.parse(JSON.stringify(interviews)),
      myAvailabilityInitialState: myAvailabilityInitialState,
    },
  };
};

export type IUserSettingsFormData = {
  email: string;
  firstName: string;
  lastName: string;
  timeZone: string;
  jobTitle: string;
  linkedInURL: string;
};

export default function MyInterviewsPage({
  user,
  interviews,
  myAvailabilityInitialState,
}) {
  const router = useRouter();

  useEffect(() => {
    if (myAvailabilityInitialState) {
      const newPath = `/interviews/my-interviews`;

      router.replace(newPath);
    }
  }, []);

  const defaultValues: IUserSettingsFormData = {
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    timeZone: user.timeZone,
    jobTitle: user.jobTitle,
    linkedInURL: user.linkedInURL,
  };

  const { handleSubmit, formState, control, errors, reset } =
    useForm<IUserSettingsFormData>({
      defaultValues,
    });

  const displayInterviewsTab = () => {
    if (
      user.type === USER_TYPES.ADMIN ||
      user.type === USER_TYPES.COMPANYSPACEOWNER
    ) {
      return <InterviewTabs tabId={INTERVIEW_TABS.MYINTERVIEWS} />;
    }
  };

  const displayInterviewsTile = () => {
    if (
      user.type === USER_TYPES.ADMIN ||
      user.type === USER_TYPES.COMPANYSPACEOWNER
    ) {
      return (
        <Typography variant="h4" style={{ marginBottom: '24px' }}>
          Interviews
        </Typography>
      );
    }

    return (
      <Typography variant="h4" style={{ marginBottom: '24px' }}>
        My Interviews
      </Typography>
    );
  };

  return (
    <BaseLayout
      companyId={user?.company?._id}
      user={user}
      myAvailabilityInitialState={myAvailabilityInitialState}
    >
      {displayInterviewsTile()}
      {displayInterviewsTab()}
      <InterviewsListPage
        companyId={user?.company?._id}
        user={user}
        interviews={interviews}
        displayInterviewMode={DISPLAY_INTERVIEWS_MODE.MY_INTERVIEWS}
      ></InterviewsListPage>
    </BaseLayout>
  );
}
