import React, { useState } from 'react';
import { LoginPage } from '@er/ui-lib';
import { useRouter } from 'next/router';
import { getSession, signIn } from 'next-auth/client';

import SnackBarErrorLayout from '@er/ui-lib/lib/common/shared/snackbar-error-layout';
import { makeStyles } from '@material-ui/core';
import useIsMobile from '@er/ui-lib/lib/common/shared/is-mobile-hook';
import { POST_LOGIN_REDIRECTS_TYPES } from './interviews/my-interviews';
import { withRequestContext } from '../middleware/withRequestContext';
import { findUserWithCompanyById } from './company-settings';
import { USER_TYPES } from '@er/data-hiring';
const labels = require('@er/ui-lib/lib/common/shared/labels.js');

const useStyles = makeStyles({
  overlay: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: 'rgba(0, 0, 0, 0.9)', // semi-transparent black background
    color: 'white',
    display: 'flex',
    flexDirection: 'column', // sets the flex direction to column
    alignItems: 'center', // horizontally centers each line of text
    justifyContent: 'center',
    fontSize: 24,
    textAlign: 'center', // centers the text inside its container
    zIndex: 1000,
  },
});

export const getServerSideProps = async ({ req, res }) => {
  await withRequestContext(req, res);

  const session = await getSession({ req: req });

  const userId = session?.user?.email;
  let user;

  if (userId) {
    const user = await findUserWithCompanyById(userId);
    if (
      user &&
      (user.type === USER_TYPES.MEMBER ||
        user.type === USER_TYPES.COMPANYSPACEOWNER ||
        user.type === USER_TYPES.ADMIN)
    ) {
      return { redirect: { destination: '/interviews/my-interviews' } };
    }
  }

  return {
    props: {
      user: user ? JSON.parse(JSON.stringify(user)) : null,
    },
  };
};

function Login() {
  const router = useRouter();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const classes = useStyles();

  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <div className={classes.overlay}>
        Sorry, mobile view is not yet supported.
      </div>
    );
  }

  async function performLogin(data) {
    const result: any = await signIn('login', {
      redirect: false,
      email: data.email,
      password: data.password,
    });

    if (result && !result.error) {
      const redirectUrl = router?.query.redirect;

      if (
        redirectUrl &&
        redirectUrl === POST_LOGIN_REDIRECTS_TYPES.MY_AVAILABILITY
      ) {
        router.push('interviews/my-interviews?openMyAvailability=true');
        return;
      } else if (
        redirectUrl &&
        redirectUrl === POST_LOGIN_REDIRECTS_TYPES.CANDIDATE_RESUME
      ) {
        const userId = router?.query.userId;
        router.push(`users/resume/${userId}`);
        return;
      }

      router.push('/users/me');
    } else {
      setErrorMessage(result?.error || labels.LoginPasswordFailure);
      setOpenSnackbar(true);
    }
  }

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  return (
    <>
      <SnackBarErrorLayout
        open={openSnackbar}
        handleClose={handleClose}
        errorMessage={errorMessage}
      ></SnackBarErrorLayout>
      <LoginPage onSubmit={(data) => performLogin(data)} />
    </>
  );
}

export default Login;
